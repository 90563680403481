<template>
  <div class="liucheng">
    <div class="kong"></div>
    <div class="liucheng-div w-1200">
      <el-steps
        :active="2"
        finish-status="success"
        simple
        style="margin-top: 20px"
      >
        <el-step title="填写企业及联系人信息"></el-step>
        <el-step title="填写店铺基础信息"></el-step>
        <el-step title="等待审核"></el-step>
      </el-steps>
      <span>您提交的店铺申请正在进行平台审核中，请耐心等待！</span>
    </div>
  </div>
</template>
<script>
import { selAreaList, getCategory, shopApplication } from '../../api/ruzhu';
export default {
  data() {
    return {
      form: {},
      options: [], //类型
    };
  },
  created() {},
  methods: {},
};
</script>
<style lang="scss" scoped>
.liucheng {
  background-color: #f5f5f5;
  .liucheng-div {
    padding-bottom: 1px;
    background-color: white;
    height: 400px;
    padding: 20px;
    padding-right: 100px;
    span {
      display: block;
      margin: auto;
      width: 656px;
      padding-top: 60px;
      text-align: center;
    }
  }
  .kong {
    width: 100%;
    height: 25px;
    background: #f5f5f5;
  }
}
</style>
