<template>
  <div>
    <div class="nav">
      <div class="menu">
        <div class="gaibian" @click="handleBackHome">
          <span>首页</span>
        </div>
        <div class="moren">
          企业入驻
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { get, post } from '@/utils/request';
export default {
  name: 'Menu',
  props: {
    msg: String,
  },
  methods: {
    handleBackHome() {
      this.$router.push({
        name: 'Home',
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.nav {
  width: 100%;
  height: 34px;
  border-bottom: 4px solid #ff902a;
  position: relative;

  .menu {
    width: 1234px;
    margin: 0 auto;
    display: flex;

    .moren {
      font-size: 20px;
      color: #282828;
      line-height: 38px;
      width: 100px;
      padding: 0 10px;
      cursor: default;
    }
    .gaibian {
      background: #ff902a;
      color: #fff;
      width: 100px;
      font-size: 20px;
      line-height: 38px;
      cursor: default;
      span {
        display: flex;
        width: 40px;
        margin: auto;
      }
    }
  }
}
</style>
