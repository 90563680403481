import {
    PrePostJson,
    PreGetData,
} from '@/utils/request';

import {
    ConvertListToChildrenTree
} from '@/utils/tree'

//省市县
export function selAreaList() {
    return PreGetData("api/address/selAreaListOption");
}

export function getCategory(params) {
    return PreGetData('api/category')
}

export function shopApplication(params) {
    return PrePostJson("api/store/shopApplication", params);
}
export function selStoreExist(params) {
    return PreGetData("api/store/selStoreExist");
}