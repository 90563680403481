<template>
  <div class="w-1920">
    <header-user></header-user>
    <ruzhu-search></ruzhu-search>
    <!-- <ruzhu-menu ref="menu"></ruzhu-menu> -->
    <div class="home">
      <div class="big-picture">
        <img src="../../assets/ruzhu/datu.png" />
      </div>

      <big-hotsale></big-hotsale>
      <store-recommend></store-recommend>
    </div>
    <div v-if="status == 0">
      <ruzhu-liucheng v-if="index == 1" ref="liucheng_1"></ruzhu-liucheng>
      <ruzhu-liucheng-2 v-if="index == 2" ref="liucheng_2"></ruzhu-liucheng-2>
      <ruzhu-liucheng-3 v-if="index == 3" ref="liucheng_3"></ruzhu-liucheng-3>
    </div>
    <div class="con w-1200" v-if="status == 1">
      欢迎入驻
    </div>
    <div class="con w-1200" v-if="status == 2">
      审核被拒绝
    </div>
    <div class="kongbai"></div>
    <foot></foot>
    <cart></cart>
  </div>
</template>
<script>
import header_user from '@/components/header';
import search from './search';
import menu from './index-menu';
import liucheng from './liucheng';
import liucheng_2 from './liucheng-2';
import liucheng_3 from './liucheng-3';
import baoyou from '@/components/order/baoyou';
import foot from '@/components/foot';
import cart from '@/components/cart';
import {
  selAreaList,
  getCategory,
  shopApplication,
  selStoreExist,
} from '../../api/ruzhu';
import { get } from '@/utils/request';
export default {
  components: {
    'header-user': header_user,
    'ruzhu-search': search,
    'ruzhu-menu': menu,
    'ruzhu-liucheng': liucheng,
    'ruzhu-liucheng-2': liucheng_2,
    'ruzhu-liucheng-3': liucheng_3,
    baoyou: baoyou,
    foot: foot,
    cart: cart,
  },
  data() {
    return {
      params: {},
      index: 1,
      status: '',
    };
  },
  created() {
    this.$nextTick(() => {
      selStoreExist().then((data) => {
        if (data) {
          this.index = 3;
          this.getstatus();
        }
      });
    });
  },
  methods: {
    onSubmit(index, data) {
      if (index == 1) {
        this.params['shopStoreAuthen'] = data.shopStoreAuthen;
        this.params['shopStoreCompany'] = data.shopStoreCompany;
        this.index = index + 1;
      } else if (index == 2) {
        this.params['shopStoreBasic'] = data.shopStoreBasic;
        shopApplication(this.params).then((x) => {
          if (x.code == 0) {
            this.index = index + 1;
          }
        });
      }
    },
    getstatus() {
      get('api/store/getstoreispass').then((res) => {
        this.status = res.data.data.storeStatus;
      });
    },
    stepLast(data) {
      this.params['shopStoreBasic'] = data.shopStoreBasic;
      this.index = this.index - 1;
    },
    getParams() {
      return this.params;
    },
  },
};
</script>
<style lang="scss" scoped>
.con {
  height: 400px;
  text-align: center;
  line-height: 400px;
  font-size: 20px;
}
.content-padding-bottom {
  padding-bottom: 30px;
}
.home {
  width: 100%;
  min-height: 100%;

  .big-picture {
    width: 100%;
    height: 602px;

    > img {
      width: 100%;
      height: 100%;
    }
  }

  .big-classify {
    width: 100%;
    height: 425px;

    &-block {
      width: calc(608px * 2);
      margin: auto;
      margin-top: -97px;
    }

    &-item {
      height: 100%;
      width: 608px;
      display: inline-block;
      position: relative;

      &-content {
        position: absolute;
        left: 0px;
        right: 0px;
        bottom: 35px;
        height: 62px;
        width: 255px;
        margin: auto;
        background: transparent url('../../assets/store/big-classifybig-ba.png')
          no-repeat;
        background-size: 100% 100%;
        padding-left: 80px;
        padding-right: 139px;
        padding-top: 19px;
        padding-bottom: 19px;

        &:after {
          content: ' ';
          position: absolute;
          display: inline-block;
          width: 41px;
          height: 41px;
          top: 0px;
          bottom: 0px;
          right: 98px;
          margin: auto;
          background: transparent
            url('../../assets/store/big-classifybig-001.png') no-repeat;
          background-size: 100% 100%;
        }

        color: #fff;

        .h1 {
          font-size: 22px;
          font-weight: 700;
          margin-bottom: 6px;
        }
      }

      > img {
        width: calc(100% - 20px);
        height: calc(100% - 20px);
        box-shadow: 5px 5px 10px #a5a5a59a, -5px -5px 10px #a5a5a59a;
        margin: 5px;
      }
    }
  }
}
</style>
